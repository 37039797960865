.why-choose-us {
  width: 100vw;
  height: 100vh;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #cc3525 0%,
    #cc3525 0%,
    #5c0b00 0.01%,
    #000000 100%
  );
  .wcu-inside {
    width: 80%;
    height: 100%;
    padding: 5rem 0;
    margin: 0 auto;

    h1.wcu-header {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 900;
      font-size: 6.5rem;
      color: #ffffff;
      padding: 5rem 0;
    }

    .wcu-middle {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .wcu-left {
        p.wcu-description {
          font-family: "Roboto Mono";
          font-style: normal;
          font-weight: 400;
          font-size: 1.5rem;
          color: #ffffff;
          padding: 5rem 0;
        }
        button.wcu-button {
          outline: 0px;
          border: 0px;

          background: #ffffff;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 20px;
          font-family: "Karla";
          font-style: normal;
          font-weight: 400;
          font-size: 1.6rem;
          padding: 1.5rem 5rem;
          /* identical to box height */

          color: #000000;
        }
      }

      .wcu-right {
        img {
          width: 40rem;
          height: auto;
        }
      }
    }
  }
}
