@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
:root {
  --color-primary: transparent;
}

* {
  margin: 0;
  padding: 0;
}

*,
*::after,
*::before {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  overflow-x: hidden;
  scroll-behavior: smooth;
  @media only screen and (max-width: 1270px) {
    font-size: 55%;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 50%;
  }

  @media only screen and (max-width: 890px) {
    font-size: 50%;
  }
  @media only screen and (max-width: 600px) {
    font-size: 40%;
  }
}

body {
}

.home-container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
}

section {
  scroll-snap-align: start;
}

.hero-content-wrapper {
  width: 100vw;
  height: 100vh;
  scroll-snap-align: start;
  background: linear-gradient(90deg, #000000 0%, #000b38 100%);
}

.hero-content {
  width: 100vw;
  height: 80vh;

  .main-content-inside {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;

    .left-main {
      width: 60%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      @media only screen and (max-width: 900px) {
        width: 100%;
      }

      .left-main-top {
        .left-main-heading {
          font-family: "Montserrat";
          font-style: normal;
          font-weight: 900;
          font-size: 5.5rem;
          color: #ffffff;
        }
      }

      .left-main-middle {
        button.left-main-button {
          width: 20rem;
          height: 6.8rem;
          text-decoration: none;
          background: #550ab7;
          box-shadow: 3px 13px 4px #001975;
          border-radius: 29px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          outline: 0px;
          border: 0px;
          /* identical to box height */
          text-align: center;
          color: #ffffff;
          z-index: 9999999;
        }
        button:hover {
          cursor: pointer;
        }
      }

      .left-main-bottom {
        h2.left-main-footer {
          font-family: "Karla";
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 23px;

          color: #c9c9c9;
        }
        .left-bottom-logos {
          margin-top: 20px;
          width: 60%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media only screen and (max-width: 600px) {
            width: 80%;
          }

          @media only screen and (max-width: 400px) {
            width: 100%;
          }
        }

        img {
          width: 8rem;
        }
      }
    }

    .right-main {
      width: 40%;
      @media only screen and (max-width: 900px) {
        display: none;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .color-span {
      color: #29c596;
    }
  }
}
