.book {
  width: 100vw;
  height: 80vh;
  background: #000000;

  .book-inside {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h1.book-header {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 900;
      font-size: 6rem;
      color: #ffffff;
    }

    .book-div {
      width: 90%;
      height: 45%;
      margin-top: 5rem;
      background: #550ab7;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      .book-div-inside {
        width: 80%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        h1.book-div-header {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 900;
          font-size: 8rem;
          color: transparent;
          -webkit-text-stroke: 2px rgb(255, 255, 255);
          /* identical to box height */
          text-transform: uppercase;
          transition: 0.4s all;

          &:hover {
            -webkit-text-stroke: 0px;
            cursor: pointer;
            color: rgba(255, 255, 255, 0.864);
          }

          @media only screen and (max-width: 1105px) {
            font-size: 6rem;
          }

          @media only screen and (max-width: 760px) {
            -webkit-text-stroke: 1px rgb(0, 0, 0);
            font-size: 4rem;
          }
        }
      }
    }
  }
}
